import * as React from "react";
import { graphql } from "gatsby";
import Layout from "@components/layout";
import ServicesComponent from "@components/Services";
import SEO from "@components/seo";
import { MetadataService } from "../../utils/MetadataService";

const IndexPage = ({uri, data}) => {
  
  const { pageMetadata, articles } = data;
  const metadata = MetadataService.parseMetadata(JSON.parse(JSON.stringify(pageMetadata || {}))).content;
  console.log({articles});
  return (
    <Layout path={uri}>
      <SEO title={metadata.page_title} />
      <ServicesComponent metadata={metadata} articles={articles} />
    </Layout>
  );
}


export const pageQuery = graphql`
  query ServicesPageQuery {
    pageMetadata(page_id: {eq: "services"}) {
      content,
      page_id
    }
    articles {
      id
      staff {
        id,
        name,
        position,
        picture {
          name,
          image
        }
      }
      created
      header
      image
      content
      title
    }
  }
`
export default IndexPage;
