import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useState, useEffect } from "react";
import * as styles from "./ServicesList.module.scss";
import DataService from "@utils/DataService";
import { Link } from "gatsby";


function createMarkup(content) {
  return {
    __html: content
  };
}

const DevelopmentTypeWidget = ({ name, svg, description }) => {

  return (
    //fix the staffing page addrees from /staffing to /services/staffing later
    <Link to={name.toLowerCase()==='staffing'?'/staffing':name.toLowerCase()} className={styles.development_type_widget}>
      <div>
        <h3 className={styles.widget_title}>
          <span className={`${styles.widget_title_span} title_span`}>{name}</span>
          <img
            src={svg}
            className={styles.widget_icon}
            alt={`Service icon`}
          />
        </h3>
        <p dangerouslySetInnerHTML={createMarkup(description)}></p>
      </div>
    </Link>
  );
}

const ServicesList = ({ metadata }) => {

  const [fetchedDevTypes, setFetchedDevTypes] = useState([]);

  useEffect(
    () => {
      DataService.getCMSData("MarketSegments").then(data => {
        data && setFetchedDevTypes(data);
      })
    }, []);
  console.log("fetchedDevTypes" , fetchedDevTypes)
  return (
    <div className="container">
      <div className={styles.services_description}>
        <p>
          {metadata.introduction}
        </p>
      </div>
      <h2 className={styles.devs_title}>
        {metadata.subtitle}
      </h2>
      {console.log("fetchedDevTypes" , fetchedDevTypes)}
      <div className={styles.development_types}>
        {fetchedDevTypes.map(type => (

          <DevelopmentTypeWidget
            {...type}
            key={type.name}
          />
        ))}
      </div>
    </div>
  );
}

export default ServicesList;
