// extracted by mini-css-extract-plugin
export const slider_container = "ServicesStackCarousel-module--slider_container--j_7Tq";
export const tech_slide = "ServicesStackCarousel-module--tech_slide--3z23H";
export const current_slide = "ServicesStackCarousel-module--current_slide--17y37";
export const pop = "ServicesStackCarousel-module--pop--10m7I";
export const techs_icons_container = "ServicesStackCarousel-module--techs_icons_container--3P89l";
export const arrow = "ServicesStackCarousel-module--arrow--mu33b";
export const arrow_up = "ServicesStackCarousel-module--arrow_up--3vTSl";
export const arrow_down = "ServicesStackCarousel-module--arrow_down--2Xw7k";
export const swipe_text = "ServicesStackCarousel-module--swipe_text--22q3A";
export const tech_slide_container = "ServicesStackCarousel-module--tech_slide_container--tCFBL";
export const bouncy = "ServicesStackCarousel-module--bouncy--tRmTS";