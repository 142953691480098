// extracted by mini-css-extract-plugin
export const devs_title = "ServicesList-module--devs_title--1WezE";
export const services_description = "ServicesList-module--services_description--2PJI0";
export const services_description_img = "ServicesList-module--services_description_img--2sEGk";
export const development_types = "ServicesList-module--development_types--18OTm";
export const development_type_widget = "ServicesList-module--development_type_widget--1jwiN";
export const wiggleLeft = "ServicesList-module--wiggle-left--2IUOj";
export const widget_title_span = "ServicesList-module--widget_title_span--2uPHR";
export const wiggleRight = "ServicesList-module--wiggle-right--hj4C6";
export const widget_icon = "ServicesList-module--widget_icon--1YDQ8";
export const widget_title = "ServicesList-module--widget_title--MpebU";
export const bouncy = "ServicesList-module--bouncy--2TCrE";
export const pop = "ServicesList-module--pop--34KYa";