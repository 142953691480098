import * as React from "react";
import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import * as styles from "./ServicesStackCarousel.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import android from "@images/tech_stack/android.svg";
import angular from "@images/tech_stack/angular.svg";
import aqws_ecs from "@images/tech_stack/aqws_ecs.svg";
import aws_ec2 from "@images/tech_stack/aws_ec2.svg";
import aws_lambda from "@images/tech_stack/aws_lambda.svg";
import aws_s3 from "@images/tech_stack/aws_s3.svg";
import aws from "@images/tech_stack/aws.svg";
import digital_ocean from "@images/tech_stack/digital_ocean.svg";
import django from "@images/tech_stack/django.svg";
import flutter from "@images/tech_stack/flutter.svg";
import gatsby from "@images/tech_stack/gatsby.svg";
import google_cloud from "@images/tech_stack/google_cloud.svg";
import graphql from "@images/tech_stack/graphql.svg";
import influx from "@images/tech_stack/influx.svg";
import mariadb from "@images/tech_stack/mariadb.svg";
import mongodb from "@images/tech_stack/mongodb.png";
import neo4j from "@images/tech_stack/neo4j.svg";
import nextjs from "@images/tech_stack/nextjs.svg";
import nodejs from "@images/tech_stack/nodejs.svg";
import postgresql from "@images/tech_stack/postgresql.svg";
import python from "@images/tech_stack/python.svg";
import react from "@images/tech_stack/react.svg";
import sql from "@images/tech_stack/sql.svg";
import wordpress from "@images/tech_stack/wordpress.svg";

const slideImgs = [
  [angular, react, wordpress, django, nextjs, android, gatsby, flutter],
  [postgresql, neo4j, sql, mariadb, aws, digital_ocean, mongodb, influx],
  [
    python,
    nodejs,
    neo4j,
    sql,
    graphql,
    google_cloud,
    aws_lambda,
    aws_ec2,
    aqws_ecs
  ]
];

function SampleNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div className={`${styles.arrow} ${styles.arrow_down}`} onClick={onClick} />
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div className={`${styles.arrow} ${styles.arrow_up}`} onClick={onClick} />
  );
}

const slideTitles = ["applications", "data storage", "data processing"];

const TechSlider = () => {
  const slider = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [large, setLarge] = useState(true);

  const largeSlideArrow = next => {
    if (next) {
      if (currentSlide === 2) setCurrentSlide(0);
      else setCurrentSlide(currentSlide + 1);
    } else {
      if (currentSlide === 0) setCurrentSlide(2);
      else setCurrentSlide(currentSlide - 1);
    }
  };

  const settings = {
    infinite: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    centerMode: true,
    centerPadding: "65px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    style: { outlineColor: "transparent" },
    onReInit: () => setLarge(true),
    beforeChange: (old, newSlide) => setCurrentSlide(newSlide),

    responsive: [
      {
        breakpoint: 1140,
        settings: {
          centerPadding: "100px",
          slidesToShow: 1,
          centerMode: true,
          infinite: true,
          onReInit: () => setLarge(false),
          focusOnSelect: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          centerPadding: "100px",
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          onReInit: () => setLarge(false),
          focusOnSelect: true
        }
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: "65px",
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          onReInit: () => setLarge(false),
          focusOnSelect: true
        }
      }
    ]
  };

  return (
    <div className={styles.slider_container}>
      <div style={{ position: "relative", paddingBottom: "35px" }}>
        {large && (
          <button
            className={`${styles.arrow} ${styles.arrow_up}`}
            onClick={() => largeSlideArrow(false)}
            aria-label="previous"
          ></button>
        )}
        <h2
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%"
          }}
        >
          Our <span className="text_orange">{slideTitles[currentSlide]}</span>{" "}
          tech stack
        </h2>
        {large && (
          <button
            className={`${styles.arrow} ${styles.arrow_down}`}
            onClick={() => largeSlideArrow(true)}
            aria-label="next"
          ></button>
        )}
      </div>
      <div className={styles.techs_icons_container}>
        <Slider {...settings} ref={slider}>
          {slideImgs.map((slider, i) => (
            <div className={styles.tech_slide_container}>
              <div
                className={`${styles.tech_slide} ${
                  i === currentSlide ? styles.current_slide : ""
                }`}
                onClick={() => {
                  if (large) setCurrentSlide(i);
                }}
              >
                {slider.map(img => (
                  <div style={{ backgroundImage: `url('${img}')` }}></div>
                  // <img src={img} alt={`${img} logo`} />
                ))}
              </div>
            </div>
          ))}
        </Slider>
        {!large && <h3 className={styles.swipe_text}>Swipe</h3>}
      </div>
    </div>
  );
};

export default TechSlider;
