import * as React from "react";
import ServicesList from "./ServicesList";
import TechsStack from "./TechsStack";
import Workflow from "./Workflow";
import ContactForm from "../ContactForm";
import SingleArticleCard from "@components/Articles/SingleArticleCard"
import ServicesStackCarousel from "@components/ServicesStackCarousel";
import { useStaticQuery, graphql } from "gatsby";

import * as styles from "./Services.module.scss";

const ServicesComponent = ({metadata, articles}) => {
  /*
  const articles = useStaticQuery(graphql`
  {
    articles {
      id
      staff {
        id,
        name,
        position,
        picture {
          name,
          image
        }
      }
      created
      header
      image
      content
      title
    }
  }
`);*/
  

  return (
  <>
    <h1 className={styles.title}>{metadata.title}</h1>
    <ServicesList metadata={metadata.servicesList} />
    <div className={styles.projects_container}>
      <div className="container">
        <h2 className={styles.projects_title}>
          {metadata.article_headers}
        </h2>
        <div className={styles.article_img_container}>
        <SingleArticleCard {...articles} noAuthor />
        </div>

      </div>
    </div>
    {/* <TechsStack /> */}
    {/* <Workflow /> */}
    <section>
      <ServicesStackCarousel />
    </section>
    <div className="container">
      <ContactForm />
    </div>
  </>
);}

export default ServicesComponent;
